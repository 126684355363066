import { __decorate } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { UPDATE_COMPONENT_HEIGHT_KEY } from '@subscription/components/Summary/Summary.config';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 */
let CheckOptionLabel = class CheckOptionLabel extends Vue {
    constructor() {
        super(...arguments);
        this.showReadMore = false;
        this.maxTextLength = 120;
    }
    get buttonLabel() {
        return this.$t(`modules.subscription.agreements.${this.showReadMore ? 'readLess' : 'readMore'}`);
    }
    get innerHTML() {
        if (this.showReadMore || !this.canTrimText) {
            return this.translation;
        }
        return this.trimText();
    }
    get canReadMore() {
        return this.translation.length > this.maxTextLength && this.canTrimText;
    }
    get canTrimText() {
        return !/(<([^>]+)>)/ig.test(this.translation);
    }
    get translation() {
        return this.$t(`modules.subscription.agreements.${this.driver}`);
    }
    toggleReadMore() {
        this.showReadMore = !this.showReadMore;
        this.updateComponentHeight();
    }
    trimText() {
        if (this.translation.length <= this.maxTextLength) {
            return this.translation;
        }
        let shortened = this.translation.substring(0, this.maxTextLength - 1);
        shortened = shortened.substring(0, shortened.lastIndexOf(' '));
        if (shortened.search(/[^\w\s]/g) === shortened.length - 1) {
            shortened = shortened.substring(0, shortened.length - 1);
        }
        return `${shortened}...`;
    }
};
__decorate([
    Prop({ type: String, required: true })
], CheckOptionLabel.prototype, "driver", void 0);
__decorate([
    Inject({ from: UPDATE_COMPONENT_HEIGHT_KEY, default: () => { } })
], CheckOptionLabel.prototype, "updateComponentHeight", void 0);
CheckOptionLabel = __decorate([
    Component({ name: 'CheckOptionLabel' })
], CheckOptionLabel);
export { CheckOptionLabel };
export default CheckOptionLabel;
