import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
/**
 * @emits change - When the main `<input>` element gets triggered.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let RadioOption = class RadioOption extends Vue {
    /**
     * Determines whether the label should be rendered as a Vue component instead of the plain string.
     */
    get isLabelComponent() {
        return typeof this.option.label === 'object' && typeof this.option.label.component === 'function';
    }
    /**
     * Handles the `@change` event on the main `<input>` element.
     */
    onInputChange() {
        return this.option;
    }
};
__decorate([
    Prop({
        type: String,
        required: false
    })
], RadioOption.prototype, "checked", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    })
], RadioOption.prototype, "name", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false,
        default: false
    })
], RadioOption.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], RadioOption.prototype, "option", void 0);
__decorate([
    Emit('change')
], RadioOption.prototype, "onInputChange", null);
RadioOption = __decorate([
    Component({ name: 'RadioOption' })
], RadioOption);
export { RadioOption };
export default RadioOption;
