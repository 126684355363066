import { __decorate } from "tslib";
import { Component, Watch, Vue, Prop, PropSync } from 'vue-property-decorator';
import { CompanyDataForm as CompanyDataInputForm } from '@profile/shared/components/CompanyDataForm/CompanyDataForm.vue';
import { UserDataForm } from '@profile/shared/components/UserDataForm/UserDataForm.vue';
let CompanyDataForm = class CompanyDataForm extends Vue {
    constructor() {
        super(...arguments);
        this.hasInvoice = false;
        this.defaultCompanyData = null;
    }
    mounted() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (this.meData && 'vatNumber' in this.meData && this.meData.vatNumber) {
            this.hasInvoice = true;
        }
        this.currentUserData = {
            firstName: (_b = (_a = this.meData) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '',
            lastName: (_d = (_c = this.meData) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : ''
        };
        this.defaultCompanyData = {
            vatNumber: this.meData && 'vatNumber' in this.meData ? this.meData.vatNumber : '',
            organization: this.meData && 'organization' in this.meData ? (_e = this.meData) === null || _e === void 0 ? void 0 : _e.organization : '',
            city: this.meData && 'city' in this.meData ? (_f = this.meData) === null || _f === void 0 ? void 0 : _f.city : '',
            postalCode: this.meData && 'postalCode' in this.meData ? (_g = this.meData) === null || _g === void 0 ? void 0 : _g.postalCode : '',
            country: this.meData && 'country' in this.meData ? (_h = this.meData) === null || _h === void 0 ? void 0 : _h.country : '',
            address: this.meData && 'address' in this.meData ? (_j = this.meData) === null || _j === void 0 ? void 0 : _j.address : ''
        };
    }
    updateHasInvoice() {
        this.currentCompanyData = this.hasInvoice ? this.defaultCompanyData : null;
        this.$emit('onToggle');
    }
};
__decorate([
    PropSync('companyData', { type: Object, required: false, default: null })
], CompanyDataForm.prototype, "currentCompanyData", void 0);
__decorate([
    PropSync('userData', { type: Object, required: false, default: null })
], CompanyDataForm.prototype, "currentUserData", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], CompanyDataForm.prototype, "meData", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], CompanyDataForm.prototype, "hasEditName", void 0);
__decorate([
    Watch('hasInvoice')
], CompanyDataForm.prototype, "updateHasInvoice", null);
CompanyDataForm = __decorate([
    Component({
        name: 'CompanyDataForm',
        components: { CompanyDataInputForm, UserDataForm }
    })
], CompanyDataForm);
export { CompanyDataForm };
export default CompanyDataForm;
