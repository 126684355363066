// Copyright © 2021 Move Closer
import PayU from './drivers/PayU.vue';
import PayURecurring from './drivers/PayURecursive.vue';
import { TransactionDriver } from '../../contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const driverProcessComponents = {
    [TransactionDriver.PayU]: PayU,
    [TransactionDriver.PayURecursive]: PayURecurring
};
/**
 * @author Maciej Perzankowski <maciej.Perzankowski@movecloser.pl>
 */
export const UPDATE_COMPONENT_HEIGHT_KEY = 'updateComponentHeight';
