import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { ConnectorErrors } from '@exception/connector-errors';
import { loadScript, unloadScript } from '@d24/modules/front';
import { EventbusType, ResourceActionFailed } from '@movecloser/front-core';
import { AlertTheme } from '@component/Alert';
import { ChecksBox } from '@component/ChecksBox';
import { AbstractDriver } from './abstract';
import { getOptionConfig } from './PayURecursive.helpers';
import { Inject } from '@plugin/inversify';
import { TransactionDriver } from '@subscription/contracts';
/**
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 */
let PayURecursive = class PayURecursive extends AbstractDriver {
    constructor() {
        super(...arguments);
        this.agreements = [];
        this.agreementsType = ['all', 'rodo', 'paymentRules', 'paymentOrder'];
        this.AGREEMENT_ALL_OPTION = 'all';
        this.isPayUInitialized = false;
        this.payU = null;
    }
    get agreementOptions() {
        return this.agreementsType.map((agreementName, index) => {
            return getOptionConfig(index, agreementName);
        });
    }
    get canProceed() {
        return this.agreement;
    }
    get config() {
        var _a;
        return ((_a = this.$configuration) === null || _a === void 0 ? void 0 : _a.byFile('payments').payu) ||
            {
                sandbox: true,
                posId: ''
            };
    }
    async beginTransaction() {
        var _a;
        if (!this.subscription || !this.subscription.transaction || !this.payU || !this.userData) {
            return;
        }
        let duration = 1;
        switch (this.subscription.period) {
            case 'annual':
                duration = 12;
                break;
            case 'semi-annual':
                duration = 6;
                break;
            case 'monthly':
            default:
                duration = 1;
                break;
        }
        this.eventBus.emit('subscription:begin', {
            subscriptionId: this.subscription.id,
            subscriptionVariant: this.subscription.period,
            subscriptionPriceNetto: Number.parseFloat(this.subscription.price) / 1.23,
            subscriptionPrice: this.subscription.price,
            subscriptionDuration: duration
        });
        this.isActing = true;
        this.errors = [];
        try {
            const tokenResult = await this.payU.tokenize('MULTI');
            if (tokenResult.status !== 'SUCCESS') {
                const formErrors = tokenResult.error.messages;
                throw new ResourceActionFailed('', ConnectorErrors.Validation, formErrors.map(m => m.message));
            }
            const token = tokenResult.body.token;
            this.$logger(token, 'debug');
            const result = this.isUpgrade
                ? await this.transactions.upgrade(this.subscription.id, TransactionDriver.PayURecursive, {
                    agreement: this.agreement,
                    cardToken: token,
                    redirectUri: this.redirectUri
                }, this.userData, this.companyData, this.promoCode)
                : await this.transactions.begin(this.subscription.id, this.subscription.transaction.token, {
                    agreement: this.agreement,
                    cardToken: token,
                    redirectUri: this.redirectUri
                }, this.userData, this.companyData, this.promoCode);
            if (result.redirect) {
                window.location.href = result.redirect;
            }
            await this.$router.push({
                name: 'subscription.status',
                params: {
                    term: this.subscription.id,
                    transaction: `${(_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.token}`
                }
            });
        }
        catch (e) {
            const status = 'status' in e && !!e.status ? e.status : ConnectorErrors.Unknown;
            switch (status) {
                case ConnectorErrors.Conflict:
                    if (this.retriesCount && (e.payload.requiredAction === 'retryTransaction' ||
                        (e.payload.extraInfo && e.payload.extraInfo.code === '110'))) {
                        this.retriesCount--;
                        this.$logger('[PayURecursive]: Conflict retryTransaction');
                        await this.registerNewTransaction();
                        this.$nextTick(() => {
                            this.beginTransaction();
                        });
                    }
                    else {
                        this.alertTheme = AlertTheme.Danger;
                        this.errors = [e.message];
                    }
                    break;
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = e.payload;
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = Array.isArray(e.message) ? e.message : [e.message];
            }
            this.isActing = false;
            this.$emit('update');
        }
    }
    markAgreementsAsChecked() {
        this.agreements = [...this.agreementOptions];
        this.agreement = true;
    }
    async destroyPayU() {
        try {
            await unloadScript(`https://secure.${this.config.sandbox ? 'snd.' : ''}payu.com/javascript/sdk`);
        }
        catch (e) {
            this.$logger(e.message, 'error');
        }
    }
    async initPayU() {
        try {
            await loadScript(`https://secure.${this.config.sandbox ? 'snd.' : ''}payu.com/javascript/sdk`);
            const optionsForms = {
                cardIcon: true,
                style: {
                    basic: {
                        fontSize: '24px'
                    }
                },
                placeholder: {
                    number: '',
                    date: 'MM/YY',
                    cvv: ''
                },
                lang: 'pl'
            };
            // initialize the SDK by providing your POS ID and create secureForms object
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const payuSdkForms = window.PayU(this.config.posId, { dev: this.config.sandbox });
            const secureForms = payuSdkForms.secureForms();
            // create the forms by providing type and options
            const cardNumber = secureForms.add('number', optionsForms);
            const cardDate = secureForms.add('date', optionsForms);
            const cardCvv = secureForms.add('cvv', optionsForms);
            // render the form in selected element
            cardNumber.render('#payu-card-number');
            cardDate.render('#payu-card-date');
            cardCvv.render('#payu-card-cvv');
            this.payU = payuSdkForms;
            this.isPayUInitialized = true;
        }
        catch (e) {
            this.errors = [
                'Błąd inicjalizacji formularza płatności PayU.',
                e.message
            ];
            this.$logger(e, 'error');
        }
        finally {
            this.$emit('update');
        }
    }
    onAgreementsChange(agreements) {
        if (agreements.length === this.agreementOptions.length) {
            return;
        }
        if (this.agreement) {
            this.agreement = false;
            if (!agreements.map(agreement => agreement.value).includes(this.AGREEMENT_ALL_OPTION)) {
                this.agreements = [];
                return;
            }
            this.agreements = agreements.filter(agreement => agreement.value !== this.AGREEMENT_ALL_OPTION);
            return;
        }
        for (const agreement of agreements) {
            if (agreement.value === this.AGREEMENT_ALL_OPTION) {
                return this.markAgreementsAsChecked();
            }
        }
        if (agreements.length === this.agreementOptions.length - 1) {
            this.markAgreementsAsChecked();
        }
    }
    async registerNewTransaction() {
        var _a, _b;
        try {
            this.$logger(`Old token: ${(_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.token}`, 'debug');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const newToken = await this.transactions.register(this.subscription.id, 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.subscription.transaction.driver);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.subscription.transaction.set('token', newToken);
            this.$logger(`New token: ${(_b = this.subscription.transaction) === null || _b === void 0 ? void 0 : _b.token}`, 'debug');
        }
        catch (e) {
            this.alertTheme = AlertTheme.Danger;
            this.errors = Array.isArray(e.message) ? e.message : [e.message];
        }
        finally {
            this.$emit('update');
        }
    }
};
__decorate([
    Inject(EventbusType)
], PayURecursive.prototype, "eventBus", void 0);
__decorate([
    Watch('agreements', { deep: true })
], PayURecursive.prototype, "onAgreementsChange", null);
PayURecursive = __decorate([
    Component({
        name: 'PayURecursive',
        components: { ChecksBox },
        created() {
            if (typeof window !== 'undefined') {
                this.initPayU();
            }
        },
        beforeDestroy() {
            if (typeof window !== 'undefined') {
                this.destroyPayU();
            }
        }
    })
], PayURecursive);
export { PayURecursive };
export default PayURecursive;
