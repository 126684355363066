import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { RadioOption } from './RadioOption';
/**
 * @emits update:model - When the new option is being selected.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let RadioBox = class RadioBox extends Vue {
    /**
     * Handles the @change event on the single `<Option>` element.
     *
     * @param option - The option being triggered.
     */
    onOptionChange(option) {
        this._model = option;
    }
};
__decorate([
    PropSync('model', { required: true })
], RadioBox.prototype, "_model", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    })
], RadioBox.prototype, "name", void 0);
__decorate([
    Prop({
        type: Array,
        required: true
    })
], RadioBox.prototype, "options", void 0);
RadioBox = __decorate([
    Component({
        name: 'RadioBox',
        components: { RadioOption }
    })
], RadioBox);
export { RadioBox };
export default RadioBox;
