import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
/**
 * @emits change - When the main `<input>` element gets triggered.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let CheckOption = class CheckOption extends Vue {
    /**
     * Determines whether the label should be rendered as a Vue component instead of the plain string.
     */
    get isLabelComponent() {
        return typeof this.option.label === 'object' && typeof this.option.label.component === 'function';
    }
    /**
     * Handles the `@change` event on the main `<input>` element.
     */
    onInputChange(event) {
        // FIXME - There is not checked value in Event.Target contract
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (event && event.target && event.target.checked) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return [event.target.checked, this.option];
        }
        else {
            return [false, this.option];
        }
    }
};
__decorate([
    Prop({ type: Boolean, required: false })
], CheckOption.prototype, "checked", void 0);
__decorate([
    Prop({ type: String, required: true })
], CheckOption.prototype, "name", void 0);
__decorate([
    Prop({ type: Object, required: true })
], CheckOption.prototype, "option", void 0);
__decorate([
    Emit('change')
], CheckOption.prototype, "onInputChange", null);
CheckOption = __decorate([
    Component({ name: 'CheckOption' })
], CheckOption);
export { CheckOption };
export default CheckOption;
