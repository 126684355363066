import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import CheckOption from './CheckOption/CheckOption.vue';
/**
 * @emits update:model - When the new option is being selected.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let ChecksBox = class ChecksBox extends Vue {
    /**
     * Handles the @change event on the single `<Option>` element.
     */
    onOptionChange(payload) {
        const [value, option] = payload;
        const modelCopy = [...this._model];
        if (value && !modelCopy.map(model => model.id).includes(option.id)) {
            modelCopy.push(option);
            this._model = modelCopy;
            return;
        }
        this._model = modelCopy.filter(model => model.id !== option.id);
    }
    getIsCheck(option) {
        return this._model.map(option => option.value).includes(option.value);
    }
};
__decorate([
    PropSync('model', { required: true })
], ChecksBox.prototype, "_model", void 0);
__decorate([
    Prop({ type: String, required: true })
], ChecksBox.prototype, "name", void 0);
__decorate([
    Prop({ type: Array, required: true })
], ChecksBox.prototype, "options", void 0);
ChecksBox = __decorate([
    Component({
        name: 'ChecksBox',
        components: { CheckOption }
    })
], ChecksBox);
export { ChecksBox };
export default ChecksBox;
