// Copyright © 2021 Move Closer
import RadioOptionLabel from './RadioOptionLabel/RadioOptionLabel.vue';
import { TransactionDriver } from '../../contracts';
/**
 * Constructs the `IRadioOption` object for the given `optionId`.
 *
 * @param id
 * @param driver
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getOptionConfig = (id, driver) => ({
    id: `payment-${id}`,
    label: {
        component: RadioOptionLabel,
        props: { driver }
    },
    value: id
});
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const extractDriverFromOption = (chosen) => {
    var _a;
    if (!chosen || typeof chosen.label === 'string' || !chosen.label.props || typeof ((_a = chosen.label.props) === null || _a === void 0 ? void 0 : _a.driver) === 'undefined') {
        return null;
    }
    return chosen.label.props.driver;
};
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const transactionDriverMap = (type, renewable) => {
    if (renewable) {
        return [TransactionDriver.PayURecursive];
    }
    return [TransactionDriver.PayU];
    // return [TransactionDriver.PayU, TransactionDriver.PayURecursive]
};
