import { __decorate } from "tslib";
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { PromotionsRepositoryType } from '@subscription/contracts';
import { Alert } from '@component/Alert';
let PromoCode = class PromoCode extends Vue {
    constructor() {
        var _a;
        super(...arguments);
        this.defaultProducts = [];
        this.errors = null;
        this.message = null;
        this.errorMessage = null;
        this.hasPromoCode = false;
        this.loading = false;
        this.promoCode = (_a = this.appliedPromoCode) !== null && _a !== void 0 ? _a : null;
    }
    get isReady() {
        return !this.hasPromoCode || !!this.promoCode;
    }
    get isChanged() {
        return this.promoCode !== this.appliedPromoCode;
    }
    get getMessageToShow() {
        if (this.message) {
            return this.message;
        }
        if (this.errorMessage) {
            return this.errorMessage;
        }
        return '';
    }
    async apply() {
        var _a, _b, _c, _d;
        if (!this.promoCode || this.loading || !this.isChanged) {
            return;
        }
        this.errorMessage = null;
        this.message = null;
        this.errors = null;
        try {
            this.loading = true;
            const data = await this.promotions.apply(this.promoCode, this.currentProducts);
            this.currentProducts = data.products;
            this.appliedPromoCode = this.promoCode;
            // TODO resolve message from api
            this.message = (_a = data.message) !== null && _a !== void 0 ? _a : 'Kod zniżkowy został dodany.';
            this.$emit('promotionUpdate', data);
        }
        catch (e) {
            this.errorMessage = (_b = e.message) !== null && _b !== void 0 ? _b : 'Wystąpił błąd';
            this.errors = (_c = e.payload.errorMessages) !== null && _c !== void 0 ? _c : [];
            this.currentProducts = (_d = e.payload.products) !== null && _d !== void 0 ? _d : this.defaultProducts;
            this.appliedPromoCode = null;
            this.$emit('promotionUpdate', e.payload);
        }
        finally {
            this.loading = false;
            setTimeout(() => this.$emit('resize'), 200);
        }
    }
    remove() {
        this.appliedPromoCode = null;
        this.promoCode = null;
        this.currentProducts = this.defaultProducts;
        this.$emit('promotionUpdate', null);
        this.$emit('resize');
    }
    onHasPromoCodeUpdate(hasPromoCode) {
        this.$emit('hasPromoCodeUpdate', hasPromoCode);
    }
};
__decorate([
    Inject(PromotionsRepositoryType)
], PromoCode.prototype, "promotions", void 0);
__decorate([
    PropSync('code', {
        type: String,
        required: false,
        default: null
    })
], PromoCode.prototype, "appliedPromoCode", void 0);
__decorate([
    PropSync('products', {
        type: Array,
        required: true
    })
], PromoCode.prototype, "currentProducts", void 0);
__decorate([
    Watch('hasPromoCode')
], PromoCode.prototype, "onHasPromoCodeUpdate", null);
PromoCode = __decorate([
    Component({
        name: 'PromoCode',
        components: {
            Alert
        },
        created() {
            this.defaultProducts = this.currentProducts;
        }
    })
], PromoCode);
export { PromoCode };
export default PromoCode;
