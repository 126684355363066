import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let RadioOptionLabel = class RadioOptionLabel extends Vue {
    get label() {
        return this.$t(`modules.subscription.paymentDrivers.${this.driver}.label`);
    }
    get icon() {
        return require('./icons/' + this.$t(`modules.subscription.paymentDrivers.${this.driver}.icon`));
    }
};
__decorate([
    Prop({ type: String, required: true })
], RadioOptionLabel.prototype, "driver", void 0);
RadioOptionLabel = __decorate([
    Component({ name: 'RadioOptionLabel' })
], RadioOptionLabel);
export { RadioOptionLabel };
export default RadioOptionLabel;
