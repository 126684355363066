// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Alert, AlertTheme } from '@component/Alert';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
import Screen from '@component/Screen/Screen.vue';
import { TransactionsRepositoryType } from '../../../contracts';
let AbstractDriver = class AbstractDriver extends Vue {
    constructor() {
        super(...arguments);
        this.agreement = false;
        this.alertTheme = AlertTheme.Danger;
        this.errors = [];
        this.isActing = false;
        this.retriesCount = 2;
    }
    get hasErrors() {
        return this.errors.length > 0;
    }
    get redirectUri() {
        var _a, _b;
        const route = this.$router.resolve({
            name: 'subscription.status',
            params: { term: this.subscription.id, transaction: `${(_b = (_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.token) !== null && _b !== void 0 ? _b : '--transaction--'}` }
        });
        return [
            this.site.address.replace(/^\/|\/$/g, ''),
            route.href.replace(/^\/|\/$/g, '')
        ].join('/');
    }
    get site() {
        return this.sites.getActiveSite();
    }
};
__decorate([
    Prop({ type: String, required: true })
], AbstractDriver.prototype, "goNextLabel", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], AbstractDriver.prototype, "isUpgrade", void 0);
__decorate([
    Prop({ type: String, required: false, default: null })
], AbstractDriver.prototype, "promoCode", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], AbstractDriver.prototype, "companyData", void 0);
__decorate([
    Prop({ type: Object, required: false, default: null })
], AbstractDriver.prototype, "userData", void 0);
__decorate([
    Prop({ type: Object, required: true })
], AbstractDriver.prototype, "subscription", void 0);
__decorate([
    Inject(SiteServiceType)
], AbstractDriver.prototype, "sites", void 0);
__decorate([
    Inject(TransactionsRepositoryType)
], AbstractDriver.prototype, "transactions", void 0);
AbstractDriver = __decorate([
    Component({ components: { Alert, Screen } })
], AbstractDriver);
export { AbstractDriver };
