// Copyright © 2021 Move Closer
import CheckOptionLabel from './CheckOptionLabel/CheckOptionLabel.vue';
/**
 * Constructs the `IChecksOption` object for the given `optionId`.
 *
 * @param id
 * @param driver
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const getOptionConfig = (id, driver) => ({
    id: `agreement-${id}`,
    label: {
        component: CheckOptionLabel,
        props: { driver }
    },
    value: driver,
    disabled: false
});
