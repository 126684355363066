import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AlertTheme } from '@component/Alert';
import { ConnectorErrors } from '@exception/connector-errors';
import { AbstractDriver } from './abstract';
import { TransactionDriver } from '@subscription/contracts';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let PayU = class PayU extends AbstractDriver {
    constructor() {
        super(...arguments);
        this.isWaitingForRedirect = false;
        this.paymentUri = '';
        this.timeToPayment = 3;
    }
    get canGoToNext() {
        return this.agreement && this.hasValidCompanyData;
    }
    get hasValidCompanyData() {
        if (this.companyData === null) {
            return true;
        }
        return Object.values(this.companyData).every(field => field !== '');
    }
    async beginTransaction() {
        if (!this.subscription || !this.subscription.transaction || !this.userData) {
            return;
        }
        this.isActing = true;
        try {
            const result = this.isUpgrade
                ? await this.transactions.upgrade(this.subscription.id, TransactionDriver.PayU, {
                    agreement: this.agreement,
                    redirectUri: this.redirectUri
                }, this.userData, this.companyData, this.promoCode)
                : await this.transactions.begin(this.subscription.id, this.subscription.transaction.token, {
                    agreement: this.agreement,
                    redirectUri: this.redirectUri
                }, this.userData, this.companyData, this.promoCode);
            if (!('redirect' in result)) {
                this.alertTheme = AlertTheme.Danger;
                this.errors = ['Nieudane połączenie z PayU'];
                return;
            }
            this.paymentUri = result.redirect;
            this.isWaitingForRedirect = true;
            setInterval(() => {
                if (this.timeToPayment === 1) {
                    this.redirectToPayU();
                    return;
                }
                this.timeToPayment--;
            }, 1000);
        }
        catch (e) {
            switch (e.status) {
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = e.payload;
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = [e.message];
            }
            this.isActing = false;
        }
        finally {
            this.$emit('update');
        }
    }
    redirectToPayU() {
        window.location.href = this.paymentUri;
    }
};
PayU = __decorate([
    Component({ name: 'PayU' })
], PayU);
export { PayU };
export default PayU;
